//@flow
import * as React from 'react'
import { gql } from 'apollo-boost'
import { EventCard } from './ui/EventCard'
import EventCardListContainer from './ui/EventCardListContainer'
import GatsbyLink from './ui/GatsbyLink'

import EventCardRowWithQuery from './EventCardRowWithQuery'
import EventCardRowHorizontalWithQuery from './EventCardRowHorizontalWithQuery'
import Viewport from './layoutHelpers/Viewport'

type EventCardsProps = {
  eventCardHorizontal?: boolean,
}
export const EventCards = (props: EventCardsProps) => {
  return props.eventCardHorizontal ? (
    <EventCardRowHorizontalWithQuery query={QUERY} />
  ) : (
    <EventCardRowWithQuery query={QUERY} />
  )
}

type Props = {
  darkMode?: boolean,
  eventCardHorizontal?: boolean,
}

export default ({ eventCardHorizontal = false }: Props) => (
  <EventCardListContainer
    title="Happening soon"
    actions={<GatsbyLink to="/events">See more events</GatsbyLink>}
  >
    <Viewport.Desktop>
      <EventCards eventCardHorizontal={eventCardHorizontal} />
    </Viewport.Desktop>
    <Viewport.Mobile>
      <EventCards eventCardHorizontal={true} />
    </Viewport.Mobile>
  </EventCardListContainer>
)

const QUERY = gql`
  query {
    events: allEventsHappeningSoon(limit: 7) {
      ...eventCardFields
    }
  }
  ${EventCard.fragments.eventCardFields}
`
