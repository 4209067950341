//@flow
import * as React from 'react'

import { css } from '@emotion/core'
import theme from '../../utils/theme'

import Button from '../../components/ui/Button'
import Text from '../../components/ui/Text'

import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

type Props = {
  children?: ?React.Node,
  backgroundImage?: ?React.Node,
}

function Jumbotron(props: Props) {
  const { backgroundImage } = props

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        height: 320px;
        overflow: hidden;

        @media (min-width: 1080px) {
          height: 528px;
        }
      `}
    >
      <div
        css={css`
          position: absolute;
          top: 0;
          bottom: 0;
          width: 100%;
          @media (min-width: 1800px) {
            top: -500px;
          }
        `}
      >
        <div
          css={css`
            /* opacity: 0.8; */
            /*TODO: Use colors*/
            background-color: #0d1225;
            filter: brightness(80%);
          `}
        >
          {backgroundImage ? backgroundImage : null}{' '}
        </div>
      </div>

      <div
        css={css`
          position: absolute;
          top: 0;
          bottom: 0;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        `}
      >
        <Text
          size={Text.Sizes.extraLarge}
          font={Text.Fonts.primary}
          weight={Text.Weights.bold}
          color={Text.Colors.white}
          css={css`
            /* Total hack for jumbotron */
            margin-bottom: 40px;
            text-align: center;
            font-size: 40px;
            text-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
            ${theme.mq.md} {
              margin-bottom: 40px;
              font-size: 60px;
            }
            ${theme.mq.lg} {
              margin-bottom: 40px;
              font-size: 80px;
            }
          `}
        >
          What's happening <br />
          in Park City?
        </Text>
        <Button.Link to={'/events'}> Browse events</Button.Link>
      </div>
    </div>
  )
}
export const query = graphql`
  query {
    lg: file(relativePath: { eq: "bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default function JumbotronWithQery() {
  const data = useStaticQuery(query)

  if (!data) {
    return null
  }
  return (
    <Jumbotron
      backgroundImage={<Img fluid={data.lg.childImageSharp.fluid} />}
    />
  )
}
