//@flow
import * as React from 'react'
import { css } from '@emotion/core'
import { Flex, Box } from 'grid-styled/emotion'
import EventCardListContainer from '../../components/ui/EventCardListContainer'
import { Colors } from '../../utils/theme'

import CategoryCard from '../../components/ui/CategoryCardLink'

import thumbnail1 from './images/tom-levold-unsplash.jpg'
import thumbnail2 from './images/artem-pochepetsky-unsplash.jpg'
import thumbnail3 from './images/gabriel-gurrola-unsplash.jpg'
import thumbnail4 from './images/michael-afonso-unsplash.jpg'
import thumbnail5 from './images/daniel-chekalov-unsplash.jpg'
import thumbnail6 from './images/bruce-mars-unsplash.jpg'
import thumbnail7 from './images/jakob-owens-unsplash.jpg'
import thumbnail8 from './images/ali-inay-unsplash.jpg'

type Props = {
  children?: ?React.Node,
}

class Categories extends React.PureComponent<Props> {
  render() {
    return (
      <EventCardListContainer
        title="Discover Events"
        css={css`
          background-color: ${Colors.tertiaryFill};
        `}
      >
        <Flex flexWrap={['wrap']}>
          <Box width={[1 / 2, 1 / 2, 1 / 2, 1 / 4]} p={[2]}>
            <CategoryCard
              title={'All Ages'}
              imageUrl={thumbnail1}
              ageGroup={['ALL_AGES']}
              category={['Children & Family']}
            />
          </Box>
          <Box width={[1 / 2, 1 / 2, 1 / 2, 1 / 4]} p={[2]}>
            <CategoryCard
              title={'21 and Older'}
              imageUrl={thumbnail2}
              ageGroup={['TWENTY_ONE_PLUS']}
            />
          </Box>
          <Box width={[1 / 2, 1 / 2, 1 / 2, 1 / 4]} p={[2]}>
            <CategoryCard
              title={'Music Scene'}
              imageUrl={thumbnail3}
              category={['Concert']}
            />
          </Box>
          <Box width={[1 / 2, 1 / 2, 1 / 2, 1 / 4]} p={[2]}>
            <CategoryCard
              title={'Theatre'}
              imageUrl={thumbnail4}
              category={['Theatre & Performing Arts']}
            />
          </Box>
          <Box width={[1 / 2, 1 / 2, 1 / 2, 1 / 4]} p={[2]}>
            <CategoryCard
              title={'Volunteer'}
              imageUrl={thumbnail5}
              category={['Charity & Volunteer']}
            />
          </Box>
          <Box width={[1 / 2, 1 / 2, 1 / 2, 1 / 4]} p={[2]}>
            <CategoryCard
              title={'Health & Wellness'}
              imageUrl={thumbnail6}
              category={['Health & Wellness', 'Outdoor & Recreation']}
            />
          </Box>
          <Box width={[1 / 2, 1 / 2, 1 / 2, 1 / 4]} p={[2]}>
            <CategoryCard
              title={'Film'}
              imageUrl={thumbnail7}
              category={['Film']}
            />
          </Box>
          <Box width={[1 / 2, 1 / 2, 1 / 2, 1 / 4]} p={[2]}>
            <CategoryCard
              title={'Food & Drink'}
              imageUrl={thumbnail8}
              category={['Food & Drink']}
            />
          </Box>
        </Flex>
      </EventCardListContainer>
    )
  }
}

export default Categories
