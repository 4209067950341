//@flow
import React from 'react'
import Layout from '../components/Layout'
import Page from '../components/ui/Page'
import Jumbotron from './home/Jumbotron'
import Categories from './home/Categories'
import TrendingEvents from '../components/TrendingEvents'
import EventsHappeningSoon from '../components/EventsHappeningSoon'

const IndexPage = () => {
  return (
    <Layout>
      <Page>
        <Jumbotron />
        <EventsHappeningSoon />
        <Categories />
        <TrendingEvents />
      </Page>
    </Layout>
  )
}

export default IndexPage
