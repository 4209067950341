//@flow
import * as React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import qs from 'qs'
import CategoryCard from './CategoryCard'

const ContainerWithLink = styled(Link)`
  text-decoration: none;
`

type Props = {
  ageGroup?: string[],
  category?: string[],
} & React.ElementProps<typeof CategoryCard>

const getParams = ({ category = [], ageGroup = [] }): string => {
  return qs.stringify({
    refinementList: {
      type: category,
      ageGroup,
    },
  })
}

const CategoryCardLink = (props: Props) => {
  const { ageGroup, category, ...rest } = props

  return (
    <ContainerWithLink to={`/events?${getParams({ category, ageGroup })}`}>
      <CategoryCard {...rest} />
    </ContainerWithLink>
  )
}

export default CategoryCardLink
