//@flow
import * as React from 'react'
import styled from '@emotion/styled'

import theme from '../../utils/theme'

const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.secondaryFill};
`

const ImageContainer = styled.img`
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  margin-bottom: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);
  opacity: 0.6;
`

const Title = styled.h3`
  margin: 0 auto;
  color: ${theme.colors.invertedFont};
  text-decoration: none;
  text-align: center;
  position: absolute;
  text-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  font-family: ${theme.fonts.body};
`

type Props = {
  title: string,
  imageUrl: string,
}

const CategoryCard = (props: Props) => {
  const { title, imageUrl } = props

  return (
    <Content>
      <ImageContainer src={imageUrl} />
      <Title>{title}</Title>
    </Content>
  )
}

export default CategoryCard
