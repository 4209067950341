// @flow
import React, { type Node } from 'react'
import { css } from '@emotion/core'

import Text from './Text'
import { Colors } from '../../utils/theme'

type Props = {
  children: Node,
  className?: string,
}

export default function Link(props: Props) {
  return (
    <Text
      size={Text.Sizes.medium}
      font={Text.Fonts.secondary}
      weight={Text.Weights.bold}
      color={Text.Colors.secondary}
      css={css`
        cursor: pointer;
        &::after {
          content: '';
          display: block;
          width: 0;
          height: 2px;
          background: ${Colors.background};
          transition: width 0.3s;
        }
        &:hover {
          color: ${Colors.background};
        }
        &:hover::after {
          width: 100%;
        }
      `}
    >
      {props.children}
    </Text>
  )
}
