// @flow
import React, { type Node, type ElementProps } from 'react'
import { css } from '@emotion/core'
import { Link as GatsbyLinkStandard } from 'gatsby'
import Link from './Link'
type Props = {
  children: Node,
  className?: string,
}
export default function GatsbyLink(
  props: Props & ElementProps<typeof GatsbyLinkStandard>
) {
  const { children, to } = props
  return (
    <GatsbyLinkStandard
      to={to}
      css={css`
        text-decoration: none;
      `}
    >
      <Link>{children}</Link>
    </GatsbyLinkStandard>
  )
}
